.iq__services{
    background-color: rgba(236,239,241,0.5);
    padding: 24px 16px;
    margin: 0 -16px;
}

.iq__services__container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iq__services__card{
    width: 270px;
    height: 270px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px 0 rgba(38,50,56,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.iq__services__card img{
    width: 150px;
    height: 153px;
}

.iq__services__card h6{
    max-width: 176px;
    margin-top: 18px;
}

@media (min-width: 960px) {
    .iq__services{
        padding: 32px 40px;
        margin: 0 -40px;
    }

    .iq__services h3{
        font-size: 32px;
        line-height: 37px;
        padding: 0 32px;
    }

    .iq__services h6{
        font-size: 20px;
        padding: 0 32px;
    }

    .iq__services__container{
        padding: 12px 32px 8px 32px;
        flex-direction: row;
        justify-content: space-between;
    }

    .iq__services__card{
        height: 200px;
        width: 200px;
    }

    .iq__services__card img{
        width: 100px;
        height: 102px;
    }

    .iq__services__card h6{
        padding: 0;
        max-width: 176px;
        font-size: 18px;
        margin-top: 13px;
    }
}