.iq__home{
    max-width: 1024px;
    padding-top: 72px;
    margin-left: auto;
    margin-right: auto;
}

.iq__home__init{
    display: flex;
    flex-direction: column;
}

.iq__home__init__img{
    width: 290px;
    height: 162px;
    margin-top: 24px;
    align-self: center;
}

.iq__home__init__button{
    display: none;
    width: 218px;
    margin: 24px 0 16px 0 !important;
    align-self: center;
}

.iq__home__init__call{
    display: flex;
    align-self: center;
    align-items: center;
    margin-bottom: 24px;
    text-decoration: none;
}

.iq__home__coverage{
    padding: 24px 0;
    display: flex;
    flex-direction: column;
}

.iq__home__coverage__section__map, .iq__home__coverage__section__smarthphone{
    display: flex;
    flex-direction: column;
}

.iq__home__coverage__map{
    width: 310px;
    height: 173px;
    margin: 40px 0;
    align-self: center;
}

.iq__home__coverage__button{
    display: flex;
    margin-top: -16px;
    align-items: flex-end;
    justify-content: space-between;
}

.iq__home__coverage__phone{
    width: 280px;
    height: 232.3px;
    margin-top: 48px;
    align-self: center;
}

.iq__home__coverage__logo{
    display: none;
}

@media (min-width: 960px) {
    .iq__home{
        padding-top: 142px;
    }
    .iq__home__init{
        padding: 0 32px;
        margin-bottom: 32px;
        height: 225px;
    }
    .iq__home__init h2{
        order: 0;
        max-width: 432px;
        font-size: 48px;
    }
    .iq__home__init h6{
        order: 1;
        max-width: 432px;
        font-size: 24px;
        line-height: 28px;
    }
    .iq__home__init__img{
        order: 4;
        align-self: flex-end;
        position: absolute;
        height: 225px;
        width: 400px;
        margin: 0;
    }
    .iq__home__init__button{
        order: 2;
        align-self: flex-start;
    }
    .iq__home__init__call{
        order: 3;
        align-self: flex-start;
        margin-left: 32px;
        text-decoration: none;
    }
    .iq__home__init__call p{
        font-size: 18px;
    }
    .iq__home__coverage{
        padding: 24px 0 24px 32px;
    }
    .iq__home__coverage__section__map{
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        flex-direction: row;
        justify-content: space-between;
    }
    .iq__home__coverage__section__map div{
        max-width: 49%;
    }
    .iq__home__coverage__section__map h3{
        font-size: 48px;
        margin-bottom: 16px;
    }
    .iq__home__coverage__section__map h6{
        font-size: 20px;
    }
    .iq__home__coverage__map{
        height: 256px;
        width: 460px;
        margin: 0;
    }
    .iq__home__coverage__section__smarthphone{
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .iq__home__coverage__section__smarthphone div{
        max-width: 544px;
    }
    .iq__home__coverage__section__smarthphone h3{
        font-size: 28px;
    }
    .iq__home__coverage__section__smarthphone p{
        font-size: 20px;
        margin-bottom: 28px;
    }
    .iq__home__coverage__button{
        margin-top: 16px;
        flex-direction: column;
        align-items: flex-start;
    }
    .iq__home__coverage__button button{
        width: 218px;
    }
    .iq__home__coverage__phone{
        height: 264.66px;
        width: 319px;
        margin: 0;
    }
    .iq__home__coverage__logo{
        display: block;
        margin-left: auto;
        margin-top: -32px;
    }
}